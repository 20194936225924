import {
  Button,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  Input,
  Stack,
  Text,
  useToast,
} from "@chakra-ui/react";
import axios from "axios";
import { ChangeEvent, useState } from "react";
import { motion } from "framer-motion";

const defaultFormData = {
  name: "",
  email: "",
  instagram: "",
};

const MotionFlex = motion(Flex);
const MotionStack = motion(Stack);
const MotionHeading = motion(Heading);
const MotionText = motion(Text);
const MotionFormControl = motion(FormControl);
const MotionInput = motion(Input);
const MotionButton = motion(Button);

const FormSection = () => {
  const toast = useToast();
  const [formData, setFormData] = useState(defaultFormData);
  const [loading, setLoading] = useState(false);

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleSubmit = async (e: any) => {
    e.preventDefault();
    setLoading(true);
    try {
      const response = await axios.post(
        "/.netlify/functions/submit-form",
        formData
      ); // Netlify function endpoint
      if (response.data.success) {
        setFormData(defaultFormData);
        toast({
          title: "Success! 🎉",
          description: `Thank you for signing up for early access to Plentichat AI!

We're excited to have you on board. You'll receive updates soon about our launch. If you have any questions, feel free to reach out to us!`,
          status: "info",
        });
        setLoading(false);
      } else {
        setLoading(false);

        throw new Error("Error occurred while sending to Airtable");
      }
    } catch (error) {
      console.log(error);
      toast({
        title: "An error occurred",
        status: "error",
      });
    }
  };

  return (
    <MotionFlex
      justify={"center"}
      w="full"
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.5 }}
    >
      <MotionStack
        borderRadius="md"
        align="center"
        py={10}
        px={5}
        spacing={6}
        w={["full", "500px"]}
        id="form"
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5 }}
      >
        <MotionHeading
          as="h1"
          fontSize={["lg", "xl"]}
          fontWeight="bold"
          color="brand.600"
          textAlign={["center", "left"]}
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5 }}
        >
          Get Early Access to Plentichat AI{" "}
        </MotionHeading>
        <MotionText
          as={"p"}
          fontSize={["md", "lg"]}
          color="gray.200"
          textAlign={"center"}
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5 }}
        >
          Be the first to boost your Instagram sales with our AI assistant. Sign
          up now!
        </MotionText>
        <form style={{ width: "100%" }} onSubmit={handleSubmit}>
          <Stack spacing={4} width="full" align={"center"}>
            <MotionFormControl
              width={"full"}
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ duration: 0.5 }}
            >
              <FormLabel color="brand.600" fontWeight={"bold"}>
                Name (optional)
              </FormLabel>
              <MotionInput
                name="name"
                value={formData.name}
                onChange={handleChange}
                bg="gray.800"
                border="none"
                _placeholder={{ color: "gray.500" }}
                size={"lg"}
                borderRadius={"xl"}
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ duration: 0.5 }}
              />
            </MotionFormControl>
            <MotionFormControl
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ duration: 0.5 }}
            >
              <FormLabel color="brand.600" fontWeight={"bold"}>
                Email
              </FormLabel>
              <MotionInput
                name="email"
                value={formData.email}
                onChange={handleChange}
                bg="gray.800"
                border="none"
                size={"lg"}
                borderRadius={"xl"}
                isRequired={true}
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ duration: 0.5 }}
              />
            </MotionFormControl>
            <MotionFormControl
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ duration: 0.5 }}
            >
              <FormLabel color="brand.600" fontWeight={"bold"}>
                Instagram Handle (optional)
              </FormLabel>
              <MotionInput
                name="instagram"
                value={formData.instagram}
                onChange={handleChange}
                bg="gray.800"
                border="none"
                _placeholder={{ color: "gray.500" }}
                size={"lg"}
                borderRadius={"xl"}
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ duration: 0.5 }}
              />
            </MotionFormControl>
            <MotionButton
              size={"lg"}
              variant={"outline"}
              colorScheme="brand"
              borderColor={"brand.600"}
              border="2px solid"
              borderRadius={"full"}
              type="submit"
              width={["full", "fit-content"]}
              isDisabled={loading}
              isLoading={loading}
              initial={{ opacity: 0, scale: 0.95 }}
              animate={{ opacity: 1, scale: 1 }}
              transition={{ duration: 0.5 }}
            >
              Join the Waitlist
            </MotionButton>
            <MotionText
              fontSize={"small"}
              fontWeight={400}
              textAlign={"center"}
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ duration: 0.5 }}
            >
              We value your privacy. Your information will be kept confidential
              and used solely for the purpose of providing early access to
              Plentichat AI.
            </MotionText>
          </Stack>
        </form>
      </MotionStack>
    </MotionFlex>
  );
};

export default FormSection;
