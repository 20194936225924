import { Container, Divider } from "@chakra-ui/react";
import HeroSection from "./Hero";
import FormSection from "./Form";
import Footer from "./Footer";

const LandingPage = () => {
  return (
    <Container maxW="container.lg" pt={10} minH="100vh">
      <HeroSection />
      <Divider borderColor={"gray.500"} my={10} />

      <FormSection />
      <Divider borderColor={"gray.500"} mt={10} />
      <Footer />
    </Container>
  );
};

export default LandingPage;
