import {
  Box,
  Button,
  Flex,
  Heading,
  Image,
  Text,
  VStack,
} from "@chakra-ui/react";
import { FaArrowRight } from "react-icons/fa6";
import { motion } from "framer-motion";

const MotionBox = motion(Box);
const MotionFlex = motion(Flex);
const MotionVStack = motion(VStack);
const MotionImage = motion(Image);
const MotionHeading = motion(Heading);
const MotionText = motion(Text);
const MotionButton = motion(Button);

const HeroSection = () => {
  return (
    <MotionFlex
      direction={["column", "row"]}
      justify="space-between"
      gap={[20, 10]}
      height={["auto", "100vh"]}
      alignItems={"center"}
      px={5}
      py={10}
      initial={{ opacity: 0, y: 50 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 1 }}
    >
      <MotionVStack
        align="start"
        spacing={[5, 10]}
        width={["100%", "60%"]}
        initial={{ opacity: 0, x: -50 }}
        animate={{ opacity: 1, x: 0 }}
        transition={{ duration: 1 }}
      >
        <MotionImage
          src="plentichat-ai-logo.png"
          alt="Plentichat AI Logo"
          height={["25px", "35px"]}
          alignSelf={["center", "auto"]}
          initial={{ opacity: 0, scale: 0.8 }}
          animate={{ opacity: 1, scale: 1 }}
          transition={{ duration: 1, delay: 0.5 }}
        />
        <MotionHeading
          as="h1"
          fontSize={["4xl", "6xl"]}
          fontWeight="bold"
          color="white"
          textAlign={["center", "left"]}
          initial={{ opacity: 0, y: 50 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 1, delay: 1 }}
        >
          AI Sales Assistant for Instagram Vendors
        </MotionHeading>
        <MotionText
          as={"p"}
          fontSize={["lg", "xl"]}
          color="gray.200"
          textAlign={"justify"}
          initial={{ opacity: 0, y: 50 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 1, delay: 1.5 }}
        >
          An AI sales assistant that helps Instagram vendors by answering
          questions about their products, taking orders, collecting payments,
          and confirming sales, even when the vendors are offline.
        </MotionText>
        <MotionButton
          size={"lg"}
          variant={"outline"}
          colorScheme="brand"
          borderColor={"brand.600"}
          border="2px solid"
          borderRadius={"full"}
          rightIcon={<FaArrowRight />}
          alignSelf={["center", "auto"]}
          as="a"
          href="#form"
          initial={{ opacity: 0, scale: 0.8 }}
          animate={{ opacity: 1, scale: 1 }}
          transition={{ duration: 1, delay: 2 }}
        >
          <Text color="white">Be the First to Try</Text>
        </MotionButton>
      </MotionVStack>
      <MotionBox
        width={["100%", "30%"]}
        initial={{ opacity: 0, x: 50 }}
        animate={{ opacity: 1, x: 0 }}
        transition={{ duration: 1, delay: 2.5 }}
      >
        <Image
          border={"4px solid"}
          borderColor="brand.600"
          borderRadius={"1rem"}
          src="lv_0_20240714084926.gif"
          alt="Chat example"
          objectFit={"cover"}
        />
      </MotionBox>
    </MotionFlex>
  );
};

export default HeroSection;
