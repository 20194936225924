import React from "react";
import {
  Box,
  Container,
  Flex,
  IconButton,
  Link,
  Text,
  VStack,
} from "@chakra-ui/react";
import { FaFacebook, FaInstagram, FaEnvelope } from "react-icons/fa";

const Footer = () => {
  return (
    <Box bg="gray.900" color="white" py={12}>
      <Container maxW="container.xl">
        <Flex
          direction={{ base: "column", md: "row" }}
          justify="space-between"
          align="center"
        >
          <VStack spacing={4}>
            <Flex>
              <IconButton
                as={Link}
                href="https://www.facebook.com/profile.php?id=61561128101240"
                aria-label="Facebook"
                icon={<FaFacebook />}
                size="lg"
                colorScheme="brand"
                variant="outline"
                isRound
                mx={2}
                target="_blank"
              />
              <IconButton
                as={Link}
                href="https://www.instagram.com/plentichat.ai/"
                aria-label="Instagram"
                icon={<FaInstagram />}
                size="lg"
                colorScheme="brand"
                variant="outline"
                isRound
                mx={2}
                target="_blank"
              />
              <IconButton
                as={Link}
                href="mailto:info@plentichat.com"
                aria-label="Email"
                icon={<FaEnvelope />}
                size="lg"
                colorScheme="brand"
                variant="outline"
                isRound
                mx={2}
                target="_blank"
              />
            </Flex>
          </VStack>
          <VStack spacing={4} mt={{ base: 4, md: 0 }}>
            <Text fontSize="sm">
              © 2024 Plentichat AI. All rights reserved.
            </Text>
            <Link
              href="https://plentichat.notion.site/Plentichat-Privacy-Policy-f3afb4c41fe545fc87f7a8f6375104f4"
              fontSize="sm"
              color="brand.500"
              target="_blank"
              textDecoration={"underline"}
              _hover={{ textDecor: "none" }}
            >
              Privacy Policy
            </Link>
          </VStack>
        </Flex>
      </Container>
    </Box>
  );
};

export default Footer;
