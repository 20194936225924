import { ChakraProvider } from "@chakra-ui/react";
import LandingPage from "./LandingPage";
import { extendTheme } from "@chakra-ui/react";
import "@fontsource/inter/400.css";
import "@fontsource/inter/500.css";
import "@fontsource/inter/600.css";
import "@fontsource/inter/700.css";

// 2. Call `extendTheme` and pass your custom values
const theme = extendTheme({
  colors: { brand: { 600: "#6691FF" } },
  styles: {
    global: {
      body: {
        bgImage: "url('background-pattern.svg')",
        bgRepeat: "repeat",
        bgSize: "40%",
        bg: "#0B111C",
        color: "white",
      },
      fonts: {
        heading: "Inter, sans-serif",
        body: "Inter, sans-serif",
      },
    },
  },
});

function App() {
  return (
    <ChakraProvider theme={theme}>
      <LandingPage />{" "}
    </ChakraProvider>
  );
}

export default App;
